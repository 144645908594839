import "../../js/calendar/css.css";
import { useState } from "react";
import Calendar from '../../js/calendar/Calendar.js'

export function InitTimetable({ app }) {

  return (
    <div>
      <Calendar app={app} />
      <br />
    </div>
  );
}

/**
 * Follow this tutorial https://medium.com/@moodydev/create-a-custom-calendar-in-react-3df1bfd0b728
 * and customised by TTNT
 * date-fns doc: https://date-fns.org/v2.21.1/docs
 */
