import { getAuth } from 'firebase/auth';

export function handleSignOut() {
  const auth = getAuth();
  auth.signOut()
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error('Error signing out:', error);
    });
}
