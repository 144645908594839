// Load JSON text from server hosted file and return JSON parsed object
export function loadJSON(filePath) {
  try {
    // Load json file
    var json = loadTextFileAjaxSync(filePath, "application/json");

    // Parse json if it is not null
    if (json) {
      return JSON.parse(json);
    }
  } catch (error) {
    console.error('Error in loadJSON:', error);
  }
}

// Load text with Ajax synchronously: takes path to file and optional MIME type
function loadTextFileAjaxSync(filePath, mimeType) {
  try {
    var xmlhttp=new XMLHttpRequest();
    xmlhttp.open("GET",filePath,false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    return xmlhttp.responseText;
  } catch (error) {
    console.log(error);
  }
}