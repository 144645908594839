import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged} from "firebase/auth";
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// React stuff
import { useEffect, useState} from 'react';

// Firebase Config
import { get_fb_config } from './js/util/get_fb_config'

// Listen to the user for role info
import { listenToUserRole } from './js/firestore/listen-user'

// Shown if role is external
import WaitingPage from './authenticated/roleUnauthenticated/WaitingPage.js';

// Shown if role is not external
import { AuthenticatedContent } from './authenticated/roleAuthenticated/authenticatedContent.js'
//import InitAppBar from './js/mui/appBar.js'

// Allow other components to access auth
let firebaseApp, firebaseAuth;

export function Firebase_Auth() {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const firebaseConfig = get_fb_config();
    firebaseApp = initializeApp(firebaseConfig);
    
    firebaseAuth = getAuth();
    try {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          //console.log('user signed in');
        
          listenToUserRole(firebaseApp, firebaseAuth, (role) => {
            setUserRole(role.toLowerCase()); // Set the user role in the component state
          });

        } else {
          //console.log('user not signed in');
          const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
          ui.start('#firebaseui-auth-container', {
            signInOptions: [
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
              signInSuccessWithAuthResult: () => false
           }
          });
        }
      });
    } catch (error) {
      // Handle the error here
      console.error('Error in onAuthStateChanged:', error);
    }

  }, []);

  // Called whenever a state updates, e.g. by using setUserRole() 
  const renderContent = () => {
    if (!userRole) {
      // Render the default Firebase login UI
      return <div id="firebaseui-auth-container"></div>;
    } else if (userRole === 'external') {
      // Render the HTML for external role
      return <WaitingPage />;
    } else {
      // Render the HTML. Additional HTML elements will be added if the role is suitable
      return <AuthenticatedContent role={userRole} app={firebaseApp} auth={firebaseAuth} />;
    }
  }

  return <>{renderContent()}</>;

}