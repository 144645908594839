import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

export default function MediaUploadFeedback({ setOpen, open }) {

  const [isVisible, setIsVisible] = useState(true); // Add a new state variable for visibility

  let { success, content } = open;
  
  const handleClose = () => {
    setIsVisible(false); // Set visibility to false when the close button is clicked
    setOpen(null);
  };

  useEffect(() => {
    if (open.success) {
      // Timer to close the successful upload notification after 5 seconds
      const timer = setTimeout(() => {
        setOpen(null);
      }, 5000);

      // Clear the timer when the component unmounts or when the alert is closed
      return () => clearTimeout(timer);
    }
  }, [open.success, setOpen]);

  // Define styles to position the alert at the top right
  const alertStyle = {
    position: 'fixed',
    top: '0px',
    right: '0px',
  };

  return (
    <div>
      {isVisible && (
        <>
          {open.success && (
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              
            >
              {open.content}
            </Alert>
          )}
          {open.success === null && (
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              
            >
              {open.content}
            </Alert>
          )}
          {open.success === false && (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              
            >
              {open.content}
            </Alert>
          )}
        </>
      )}
    </div>
  );
}