import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export function DynamicPositionMenu({position, setPosition, menu1 = null, handleMenu1 = null, menu2 = null, handleMenu2 = null, menu3 = null, handleMenu3 = null, menu4 = null, handleMenu4 = null, menu5 = null, handleMenu5 = null}) {  

  const handleClose = () => {
    setPosition(null);
  };

  // Set open to false if no menu items have been provided
  var open = false;
  if (menu1 !== null || menu2 !== null || menu3 !== null || menu4 !== null || menu5 !== null) {
    open = true;
  }

  var x = 0;
  var y = 0;

  if (position !== null) {
    const position_X = position.x
    if (position_X !== null) x = position_X;

    const position_Y = position.y
    if (position_Y !== null) y = position_Y;
  }


  return (
    <Menu
      id="basic-menu"
      open={true}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{ left: x, top: y }}
    >
      {menu1 && (
        <MenuItem onClick={handleMenu1}>{menu1}</MenuItem>
      )}
      {menu2 && (
        <MenuItem onClick={handleMenu2}>{menu2}</MenuItem>
      )}
      {menu3 && (
        <MenuItem onClick={handleMenu3}>{menu3}</MenuItem>
      )}
      {menu4 && (
        <MenuItem onClick={handleMenu4}>{menu4}</MenuItem>
      )}
      {menu5 && (
        <MenuItem onClick={handleMenu5}>{menu5}</MenuItem>
      )}
    </Menu>
  );

}
