import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';

import UploadFileIcon from '@mui/icons-material/UploadFile'; // upload icon for uploading media

import EditVideoThumbnail from './MediaVideoThumbnail.js'

export default function MediaEditForm({setOpen, saveDirectChanges, handleResponse, chosenMedia}) {

  const [isDragging, setIsDragging] = useState(false); // used to detect if a user drags a file over a div
  const [fileObject, setFileObject] = useState(null);
  const [fileURL, setFileURL] = useState(null); // media 

  // Handle if a user has clicked media and doesn't want to upload new media
  useEffect(() => {
    // Check if chosenMedia exists and has the 'url' property
    if (chosenMedia && chosenMedia.url) {
      // Assuming 'url' is a property of chosenMedia that contains the URL you want
      setFileURL(chosenMedia.url);

      // Extract contentType from metadata and save it as 'type' in the file object
      const { contentType } = chosenMedia.metadata;
      const updatedFileObject = {
        //...chosenMedia,
        type: contentType, // Save contentType as 'type' in the file object
      };
      setFileObject(updatedFileObject);
    }
  }, [chosenMedia]);

  // Show media thumbnail settings
  const [openVideoThumbnailSettings, setOpenVideoThumbnailSettings] = useState(false); // used for editing a calendar entry

  const handleClose = () => {
    handleResponse(false);
    setOpen(false);
  };

  const handleSave = () => {
    handleResponse({ save: true, fileObject: fileObject, fileURL: fileURL });
    setOpen(false);
  };

  const triggerFileChoose = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.addEventListener('change', handleFileChoose);
    // Trigger the file input dialog box
    fileInput.click();
  };

  const handleFileChoose = (event) => {
    const f = event.target.files[0]; // Get the file the user has chosen
    setFileObject(f);
    generateFilePreviewURL(f); // Generate temproary URL to preview the file that has been uploaded
    event.target.value = ''; // Reset the file input element
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const f = event.target.files[0]; // Get the file the user has chosen
    setFileObject(f);
    generateFilePreviewURL(f); // Generate temproary URL to preview the file that has been uploaded
    event.target.value = ''; // Reset the file input element
  };

  const generateFilePreviewURL = (f) => {
    const blobURL = URL.createObjectURL(f);
    setFileURL(blobURL);    
  }

  const editThumbnailClick = () => {
    setOpenVideoThumbnailSettings(true) // open media edit form
  }
  
  return (
    <div>
      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          {'Edit Media Properties'}
        </DialogTitle>
        <DialogContent>
        <Grid container>
          <Grid 
            xs={12} 
            sm={4}
            item
            container
            direction="column"
            style={{ paddingRight: '10px' }} 
          >
              <TextField
                label="Name"
                type="text"
                variant="standard"
                defaultValue={''}
                size="small"
                multiline
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                label="Category"
                type="text"
                variant="standard"
                defaultValue={''}
                size="small"
                InputProps={{ disableUnderline: true }}
              />
          </Grid>
          <Grid 
            xs={12} 
            sm={8}
            item
            container
            direction="column"
          >
              <div
                className={`drop-area ${isDragging ? 'dragging' : ''}`}
                onDragEnter={!fileURL ? handleDragEnter : undefined}
                onDragLeave={!fileURL ? handleDragLeave : undefined}
                onDragOver={!fileURL ? (event) => event.preventDefault() : undefined}
                onDrop={!fileURL ? handleDrop : undefined}
                onClick={!fileURL ? triggerFileChoose : undefined}
                style={{
                  cursor: !fileURL ? 'pointer' : 'default',
                  position: "relative",
                  height: '60vh',
                  width: '100%',
                  backgroundColor: !fileURL ? '#ddd' : undefined,
                  borderRadius: "5px", // Adjust the value as needed for the desired roundness
                  border: !fileURL ? '1px dashed #888' : undefined, // Adjust the border properties as needed
                    
                }}
              >
                {!fileURL ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      userSelect: 'none',
                    }}
                  >
                    <UploadFileIcon />
                    <span>
                      {isDragging ? 'Drop the file here' : 'Click or drag and drop a file'}
                    </span>
                  </div>
                ) : ( fileObject.type && fileObject.type.includes('image')) ? (
                  <img
                    src={fileURL}
                    alt="Media Thumbnail"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain"}}
                    />
                ) : ( fileObject.type && fileObject.type.includes('video')) ? (
                  <div
                    style={{
                      height: "95%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <video
                      width="100%"
                      height="100%"
                      controls
                      poster={fileObject.thumbnailDataURL ? fileObject.thumbnailDataURL : ""}
                      onEnded={(event) => {
                        event.target.load(); //reload video when finished, this shows the poster
                      }}
                    >
                      <source src={fileURL} type={fileObject.type} />
                    </video>
                    <Button onClick={editThumbnailClick}>Edit Thumbnail</Button>
                  </div>
                ) : null}
              </div>
          </Grid>
        </Grid>
      </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{'Cancel'}</Button>
          <Button onClick={handleSave}>{saveDirectChanges ? 'Save' : 'Next'}</Button>
        </DialogActions>
      </Dialog>
      {openVideoThumbnailSettings && <EditVideoThumbnail setOpen={setOpenVideoThumbnailSettings} video={fileObject} videoURL={fileURL} setFile={setFileObject}/>}
    </div>
  );
}