import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';

import UploadFileIcon from '@mui/icons-material/UploadFile'; // upload icon for uploading media

export default function EditVideoThumbnail({setOpen, video, videoURL, setFileToUpload}) {

  /*const [videoElement, setVideoElement] = useState(null);
  const [imageElement, setImageElement] = useState(null);

  const [videoTime, setVideoTime] = useState(0);
  const [videoThumbnailDataURL, setVideoThumbnailDataURL] = useState(null);

  const textFieldRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoRefSet, setIsVideoRefSet] = useState(false);

  // Set up the video div.
  useEffect(() => {
    setVideoElement(
      <video
        style={{
          height: '50vh',
        }}
        width="100%"
        height="100%"
        controls
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
      >
        <source src={videoURL} type={video.type} />
      </video>
    );
    setIsVideoRefSet(true);
  }, [videoURL, video.type]);


  // Set videoThumbnail from input mediaFile
  useEffect(() => {
    if (video && mediaFile.thumbnailDataURL) {
      // Check if mediaFile contains a key
      setVideoThumbnailDataURL(mediaFile.thumbnailDataURL);
    } else if (isVideoRefSet) {
      const video = videoRef.current;
      if (video) {
        video.currentTime = 0.000001; // Set the video time to the beginning'
        drawImageFromVideo(video);
      }
    }
  }, [mediaFile, isVideoRefSet]);

  // Set up the image div.
  useEffect(() => {
    if (videoThumbnailDataURL) {
      setImageElement(
        <img
          src={videoThumbnailDataURL}
          alt="Media Thumbnail"
          style={{
            height: '50vh',
            objectFit: 'contain',
          }}
        />
      );
    }
  }, [videoThumbnailDataURL]);

  const updateVideoTime = () => {
    const video = videoRef.current;
    const newTime = parseFloat(textFieldRef.current.value);
    if (!isNaN(newTime)) {
      video.currentTime = newTime;
      setVideoTime(newTime);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {

    // Create a new mediaFile object with the original file
    const updatedMediaFile = new File([mediaFile], mediaFile.name, {
      type: mediaFile.type,
      lastModified: mediaFile.lastModified,
    });

    // Add a child property for the thumbnail
    updatedMediaFile.thumbnailDataURL = videoThumbnailDataURL;

    // Update the mediaFile state with the new object
    setMediaFile(updatedMediaFile);

    setOpen(false);
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    if (currentTime !== videoTime) {
      setVideoTime(currentTime);
      updateTextFieldValue(currentTime);
    }
    drawImageFromVideo(video);
  };

  const drawImageFromVideo = (video) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const frameData = canvas.toDataURL('image/jpeg');
    setVideoThumbnailDataURL(frameData);
  };

  const updateTextFieldValue = (time) => {
    textFieldRef.current.value = time.toFixed(2);
  };


  const handleTextFieldOnChange = (event) => {
    const newTime = parseFloat(event.target.value);
    console.log(newTime);
    if (!isNaN(newTime)) {
      updateVideoTime(newTime);
    }
  };

  return (
    <div>
      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="false"
      >
        <DialogTitle id="alert-dialog-title">
          {'Edit Video Thumbnail'}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={6} container direction="column">
              {imageElement}
              <TextField
                type="text"
                variant="standard"
                size="small"
                fullWidth
                onChange={handleTextFieldOnChange}
                inputRef={textFieldRef} // allow user input aswell as changes by system (when user scrubs video)
                InputProps={{ 
                  disableUnderline: true, 
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
              />
            </Grid> 
            <Grid item xs={12} sm={6}>
              {videoElement}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{'Cancel'}</Button>
          <Button onClick={handleSave}>{'Save'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  */
}