import {getFirestore, collection, doc, addDoc, updateDoc, deleteDoc, Timestamp} from "firebase/firestore"; 

export function UpdateTimetableEvent ({app, documentId, title, startDateTime, endDateTime, detail, coach, newMedia}) {

  return new Promise((resolve, reject) => {

    // Update the document with your new data
    const dataToUpdate = {};

    // if values have been provided as an input, add it to the dataToUpdate
    if (title !== undefined && title !== null)                  dataToUpdate.title = title;
    if (startDateTime !== undefined && startDateTime !== null)  dataToUpdate.startDateTime = Timestamp.fromDate(startDateTime);
    if (endDateTime !== undefined && endDateTime !== null)      dataToUpdate.endDateTime = Timestamp.fromDate(endDateTime);
    if (detail !== undefined && detail !== null)                dataToUpdate.detail = detail;
    if (coach !== undefined && coach !== null)                  dataToUpdate.coach = coach;
    
    // Set the entire array to the contents of newMedia input prop
    if (newMedia !== undefined && newMedia !== null) {
      dataToUpdate.medias = newMedia;
    }

    const firestore = getFirestore(app);
    const collectionRef = collection(firestore, 'timetable');

    if (!documentId) { // Add document to firestore
      addDoc(collectionRef, dataToUpdate)
        .then((docRef) => {
          resolve(); // Resolve the promise when the addition is successful
        })
        .catch((error) => {
          console.error('change_timetable', 'Error adding document in Firestore:', error);
          reject(error); // Reject the promise if there is an error
        });
    } else if (Object.keys(dataToUpdate).length > 0) { // Change document in firestore
      const docRef = doc(collectionRef, documentId); // Get a reference to the specific document
      updateDoc(docRef, dataToUpdate) // Call updateDoc on the document reference
        .then(() => {
          resolve(); // Resolve the promise when the update is successful
        })
        .catch((error) => {
          console.error('change_timetable', 'Error updating document in Firestore:', error);
          reject(error); // Reject the promise if there is an error
        });
    } else {
      resolve(); // Resolve the promise when the update is successful
    }
  });
}

export function DeleteTimetableEvent({ app, documentId }) {
  return new Promise((resolve, reject) => {
    if (!documentId) {
      // If documentId is not provided, there's nothing to delete
      resolve(); // Resolve the promise as there's no action required
    } else {
      const firestore = getFirestore(app);
      const collectionRef = collection(firestore, 'timetable');
      const documentRef = doc(collectionRef, documentId);

      // Delete the document from Firestore
      deleteDoc(documentRef)
        .then(() => {
          resolve(); // Resolve the promise when the deletion is successful
        })
        .catch((error) => {
          console.error('delete_timetable', 'Error deleting document in Firestore:', error);
          reject(error); // Reject the promise if there is an error
        });
    }
  });
}