import { getFirestore, doc, collection, onSnapshot } from 'firebase/firestore';

let previousRole = null;


export function listenToUserRole(firebaseApp, firebaseAuth, handleUserRole) {
  const user = firebaseAuth.currentUser;

  if (user) {
    const collectionRef = collection(getFirestore(firebaseApp), 'users');
    const documentRef = doc(collectionRef, user.uid);

    onSnapshot(documentRef, (documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        const currentRole = data.role;

        if (currentRole !== previousRole) {
          // Role has changed
          //console.log('Previous role:', previousRole);
          //console.log('Current role:', currentRole);
          previousRole = currentRole; // Update the previous role
          handleUserRole(currentRole);
        }
      } else {
        // Document does not exist
        console.log('Current user document does not exist.');
      }
    });
  } else {
    console.log("Can't access Firestore, the user has likely signed out");
  }
}
