// WaitingPage.js

import React from 'react';
import './WaitingPage.css'; // Import the CSS file

import LinearIndeterminate from '../../js/mui/progressIndicators.js'; // progress indicator

import { handleSignOut } from '../../js/firebase/signOut.js'

function WaitingPage() {

  return (
    <div className="container">
      <h1>Waiting for Authorization</h1>
      <LinearIndeterminate />
      <p>Your account is currently waiting to be authorized.</p>
      <button id="signOut" className="sign-out-button" onClick={handleSignOut}>Exit</button>
    </div>
  );
}

export default WaitingPage;
