//import React, { useState } from 'react';
import { getMetadata, getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export function AddFile ({storagePath, file, progressCallback, autoOverwrite = true, overwriteCallback}) {

  let fileName = null;

  return new Promise((resolve, reject) => {
    if (file) {
      if (!fileName) {
        if (file.name) fileName = file.name;
      }

      // Get Firebase storage references
      const storage = getStorage();
      storagePath = storagePath + '/' + fileName;
      const storageRef = ref(storage, storagePath);
      
      // Set cache control to 1 month (in seconds)
      const cacheControlInSeconds = 60 * 60 * 24 * 30;
      const uploadTask = uploadBytesResumable(storageRef, file, {
        customMetadata: {
          'cacheControl': `public, max-age=${cacheControlInSeconds}`
        }
      });

      if (autoOverwrite !== true) {
        getMetadata(storageRef)
        .then((metadata) => {
          // File exists
          overwriteCallback();
        })
        .catch((error) => {
          if (error.code === 'storage/object-not-found') {
            // File does not exist
            uploadTask.on('state_changed', (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                progressCallback(progress);
            }, (uploadError) => {
              reject(uploadError.message);
            }, () => {
              resolve(storagePath);
            });
          }
        });
      } else {
        uploadTask.on('state_changed', (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            progressCallback(progress);
        }, (error) => {
          reject(error.message);
        }, () => {
          resolve(storagePath);
        });
      }
    }
  });
}

export function GetDownloadURLAndMetadata({filePath}) {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storageRef = ref(storage, filePath);
    getDownloadURL(storageRef)
      .then((downloadURL) => {
        // Next, get the metadata for the file
        getMetadata(storageRef)
          .then((metadata) => {
            // Combine the download URL and metadata and resolve the promise
            const result = {
              downloadURL: downloadURL,
              metadata: metadata,
            };
            resolve(result);
          })
          .catch((error) => {
            // If there's an error fetching metadata, resolve with just the download URL
            resolve({ downloadURL: downloadURL });
          });
      })
      .catch((error) => {
        reject(error.message)
      })
    });
}