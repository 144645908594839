import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// used to inputting dates
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';

import { addHours } from 'date-fns';

import Grid from '@mui/material/Grid';

import MediaEditForm from './MediaEdit.js'


import UploadFileIcon from '@mui/icons-material/UploadFile'; // upload icon for uploading media

import {AddFile, GetDownloadURLAndMetadata} from '../../../firebase/cloudStorage.js'
import MediaUploadFeedback from '../../Alerts/SuccessFailure.js'

import OverwriteMedia from '../Confirmations/MediaUploadOverwrite.js'

import { UpdateTimetableEvent } from '../../../firestore/change-timetable.js'

// Allow right click of media
import { DynamicPositionMenu } from "../../Menu.js"

// Confirmation of removal of media
import MediaRemovalAlert from "../Confirmations/CalendarEventMediaRemoval.js";

export default function CalendarDialogEditForm({ firebaseApp, setOpen, handleResponse, firestoreDocument, newFirestoreDocument}) {

  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(addHours(new Date(), 1));
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [coach, setCoach] = useState('');

  // Needed for media content
  //const [visibleMediaMap, setVisibleMediaMap] = useState(new Map());
  const [visibleMediaMap, setVisibleMediaMap] = useState(new Map());
  const [chosenMedia, setChosenMedia] = useState(null);
  const [deleteMediaMap, setDeleteMediaMap] = useState(new Map()); // array to hold any medias that are to be deleted.

  // Track if the screen is going to be updated. Ensures the alerts are shown correctly.
  const [isProcessing, setIsProcessing] = useState(false);

  // Function to add an element to deleteMediaMap
  const addToDeleteMediaMap = (mediaItem) => {
    setDeleteMediaMap((prevDeleteMediaMap) => {
      const updatedMap = new Map(prevDeleteMediaMap); // Create a new Map as a copy of the previous one
      updatedMap.set(mediaItem.cloudStoragePath, mediaItem); // Add or update the mediaItem in the Map using its ID as the key
      return updatedMap;
    });
  };

  // Effect to run code whenever deleteMediaArray changes
  useEffect(() => {
    // Iterate through each key in deleteMediaMap and call removeFromVisibleMediaMap
    deleteMediaMap.forEach((value, key) => {
      removeFromVisibleMediaMap(key);
    });
    // Clear deleteMediaMap after processing all keys
    setDeleteMediaMap(new Map());
  }, [deleteMediaMap]);

  // Confirmation for removing media
  const [openMediaRemovalForm, setOpenMediaRemovalForm] = useState(false);

  const handleMediaRemovalAlert = (deleteMedia) => {
    addToDeleteMediaMap(chosenMedia);
    setChosenMedia(null);
  }

  // allow right click functionality --------------------------------------------------------
  const [positionRightClick, setPositionRightClick] = useState(null); // set position of right click menu
  // states to show move up and move down items in the menu
  const[rightClickMenu_MoveUp, setRightClickMenu_MoveUp] = useState(null)
  const[rightClickMenu_MoveDown, setRightClickMenu_MoveDown] = useState(null)

  const adjustIndex = (mediaItem, changeBy) => {
    console.log('adjustIndex', 'mediaItem', mediaItem, 'changeBy', changeBy);
    const mediaItemIndex = mediaItem.index;
    setVisibleMediaMap((prevMediaMap) => {
      // Create a new map (object) to store the updated values
      const updatedMediaMap = Object.fromEntries(
        // Use Object.entries to convert the map into an array of key-value pairs
        Object.entries(prevMediaMap).map(([key, value]) => {
          if (changeBy > 0 && value.index === mediaItemIndex + 1) {
            // If the current value's index is 1 greater than the original mediaItem's index
            // and the changeBy is positive (increasing the index), decrease its index by 1
            return [key, { ...value, index: value.index - 1 }];
          } else if (changeBy < 0 && value.index === mediaItemIndex - 1) {
            // If the current value's index is 1 less than the original mediaItem's index
            // and the changeBy is negative (decreasing the index), increase its index by 1
            return [key, { ...value, index: value.index + 1 }];
          } else if (key === mediaItem.cloudStoragePath) {
            // If there's a match, create a new object with the updated index value
            return [key, { ...value, index: value.index + changeBy }];
          } else {
            // If there's no match or the index is not affected, keep the original key-value pair unchanged
            return [key, value];
          }
        })
      );
      // Return the updatedMediaMap to update the state
      return updatedMediaMap;
    });
  };

  // whenever positionRightClick becomes falsy, make rightClickMenu_MoveUp/MoveDown null
  useEffect(() => {
    if (!positionRightClick) {
      setRightClickMenu_MoveUp(null);
      setRightClickMenu_MoveDown(null);
    }
  }, [positionRightClick]);

  const handleDynamicPositionRightClickMenu_MoveUp = () => {
    setPositionRightClick(null); // close menu
    adjustIndex(chosenMedia, -1);
    setChosenMedia(null);

  };
  const handleDynamicPositionRightClickMenu_MoveDown = () => {
    setPositionRightClick(null); // close menu
    adjustIndex(chosenMedia, 1);
    setChosenMedia(null);
  };
  const handleDynamicPositionRightClickMenu_DeleteFromEvent = () => {
    setPositionRightClick(null); // close menu
    setOpenMediaRemovalForm(true);
  };

  const handleMediaRightClick = (event, mediaItem, index) => {
    event.preventDefault();
    setChosenMedia(mediaItem);
    if (index > 0) setRightClickMenu_MoveUp("Move Up")
    const mediaMapSize = (Object.keys(visibleMediaMap).length) - 1; // -1 to account for the placeholder
    if ((index + 1) < mediaMapSize) setRightClickMenu_MoveDown("Move Down") // +1 as index starts at 0 not 1

    setPositionRightClick({
      x: event.clientX,
      y: event.clientY,
    });
  };
  // -------------------------------------------------------------------------------

useEffect(() => {
  if (firestoreDocument?.startDateTime) {
    setStartDateTime(firestoreDocument.startDateTime.toDate());
  } else if (newFirestoreDocument?.startDateTime) {
    setStartDateTime(newFirestoreDocument.startDateTime);
  } else {
    setStartDateTime(new Date());
  }
}, [firestoreDocument?.startDateTime, newFirestoreDocument?.startDateTime]);

useEffect(() => {
  if (firestoreDocument?.endDateTime) {
    setEndDateTime(firestoreDocument.endDateTime.toDate());
  } else if (newFirestoreDocument?.endDateTime) {
    setEndDateTime(newFirestoreDocument.endDateTime);
  } else {
    setEndDateTime(addHours(new Date(), 1));
  }
}, [firestoreDocument?.endDateTime, newFirestoreDocument?.endDateTime]);

useEffect(() => {
  if (firestoreDocument?.title) {
    setTitle(firestoreDocument.title);
  } else if (newFirestoreDocument?.title) {
    setTitle(newFirestoreDocument.title);
  }
}, [firestoreDocument?.title, newFirestoreDocument?.title]);

useEffect(() => {
  if (firestoreDocument?.detail) {
    setDetail(firestoreDocument.detail);
  } else if (newFirestoreDocument?.detail) {
    setDetail(newFirestoreDocument.detail);
  }
}, [firestoreDocument?.detail, newFirestoreDocument?.detail]);

useEffect(() => {
  if (firestoreDocument?.coach) {
    setCoach(firestoreDocument.coach);
  } else if (newFirestoreDocument?.coach) {
    setCoach(newFirestoreDocument.coach);
  }
}, [firestoreDocument?.coach, newFirestoreDocument?.coach]);

const [isFirstRender, setIsFirstRender] = useState(false);

useEffect(() => {
  // Convert the array in firestore to a map in javascript.
  if (firestoreDocument?.medias) {
    const fsMedias = firestoreDocument.medias.reduce((map, mediaItem, index) => {
      map[mediaItem] = {
        cloudStoragePath: mediaItem,
        cloudSaved: true,
        index: index,
      };
      return map;
    }, {});
      
    const visibleMediaToDelete = [];
    const visibleNonSavedMediaToRefresh = [];
    Object.entries(visibleMediaMap).forEach(([key, value]) => { 
      if (!value.placeholder) visibleMediaToDelete.push(key) // save media that we are going to delete, we will take the latest from firestore to replace this
      if (value.cloudSaved === false) visibleNonSavedMediaToRefresh.push(value); // save media that we need to add back in
    });
       
    // Delete all from the visible media, we'll later add the new entries from firestore.
    visibleMediaToDelete.forEach((key) => {delete visibleMediaMap[key];});

    const processPromisesConcurrently = () => { // Concurrently get the download URLs for the medias saved in firestore
      setIsProcessing(true);
        
      const resolvedMediaItems = {}; // Create an object to store the promises results
      const promises = []; // Create an array to store the promises
        
      // Loop through firestore medias
      Object.entries(fsMedias).forEach(([fsMediaPath, mediaItem]) => { 
        promises.push( // Push each promise to the promises array
          GetDownloadURLAndMetadata({filePath: fsMediaPath})
            .then((result) => {
              // Update the URL and metadata in the mediaItem object. This will not update the mediaItems state.
              mediaItem.url = result.downloadURL;
              mediaItem.metadata = result.metadata;
              resolvedMediaItems[fsMediaPath] = mediaItem; // store the result in the promises result object
            })
            .catch((error) => { // Handle any errors
              console.error('Error getting download URL:', error);
            })
        );
      });

      Promise.all(promises).then(() => { // Define what will happen when concurrent processing has finished
        //fsMedias.forEach((mediaItem) => { // Loop through the new firestore medias
        Object.entries(fsMedias).forEach(([fsMediaPath, mediaItem]) => { 
          addToMediaMap(mediaItem); // Earlier we deleted all firestore entries, now we're adding them again and thus handling any changes (including changes to order)
        });

        visibleNonSavedMediaToRefresh.forEach((mediaItem) => { // Loop through media that was removed but wasn't saved in firestore
          // If mediaItem does not exist in the firestore medias map
          if (!(mediaItem.cloudStoragePath in fsMedias)) addToMediaMap(mediaItem); // add the media references to the visible state. This ensures they are always displayed at the end.
        });

        setIsProcessing(false);
      })
      .catch((error) => {
        console.error('Error processing promises:', error);
        setIsProcessing(false); // Ensure that isProcessing is set back to false even if there's an error
      });
    };
    processPromisesConcurrently(); // Start the concurrent processing defined above.
  } else {
    console.log('no media');
    //addToMediaMap({placeholder: true})
    setVisibleMediaMap({placeholder : {placeholder : true}});
  }
}, [firestoreDocument?.medias]);

  const handleClose = () => {
    handleResponse(false);
    setOpen(null);
  };

  const handleSave = () => {

    const dataToSave = {} // need to switch this to a state

    // Make success alerts more generic
    const successAlertMessage = "Changes saved.";
    const failureAlertMessage = "Changes could not be saved.";

    const visibleOrderedMediaKeys = createdSortedArrayFromMapKeys('index', visibleMediaMap);

    // Filter map to get only items that need to be saved in firebase cloud storage
    const AddFirebaseCloudStorage = Object.values(visibleMediaMap)
      .filter((value) => value.cloudSaved === false);

    // Check if AddFirebaseCloudStorage is not empty
    if (AddFirebaseCloudStorage.length > 0) {
      // Attempt to upload all items with a cloudSaved set to false
      const uploadPromises = [];
      AddFirebaseCloudStorage.forEach((item) => {
        if (item.fileObject) {
          // Add the file to Firebase Cloud Storage
          const promise = 
            AddFile({ 
              storagePath: 'media', 
              file: item.fileObject,
              autoOverwrite: true,
              progressCallback: (progress) => {
                setOpenMediaUploadAlert({ success: null, content: 'Uploading media: ' + progress + '%' });
              },
              overwriteCallback: () => {
                setOpenOverwriteMediaForm(true); // open form to ask to overwrite the file
              },
            })
            .then((storagePath) => {
              // Firebase Cloud Storage upload successful, now try to link the file with the timetable event
              //item.storagePath = storagePath; // Update the storagePath in the mediaList
              console.log("upload finished", storagePath);
              return storagePath;
            })
            .catch((error) => {
              setOpenMediaUploadAlert({ success: false, content: 'Media upload failed. ' + error });
              throw error; // Rethrow the error to stop the Promise chain
            });

          uploadPromises.push(promise);
        }
      });

      // Wait for all file uploads to complete
      Promise.all(uploadPromises)
      .then((storagePaths) => { // All the Firebase Cloud Storage uploads were successful
        console.log('promises have finished');
        dataToSave.newMedia =  visibleOrderedMediaKeys;
        console.log('isObjectEmpty', isObjectEmpty(dataToSave));
      })
    } else if (JSON.stringify(visibleOrderedMediaKeys) !== JSON.stringify(firestoreDocument?.medias)) {
        // Visible media is different to firestore, update firestore.
        dataToSave.newMedia =  visibleOrderedMediaKeys;
    }

    // Determine what data needs to be updated
    if (firestoreDocument?.title !== title) dataToSave.title =  title
    if (!areDatesSame(firestoreDocument?.startDateTime.toDate(), startDateTime)) dataToSave.startDateTime =  startDateTime
    if (!areDatesSame(firestoreDocument?.endDateTime.toDate(), endDateTime)) dataToSave.endDateTime =  endDateTime
    if (firestoreDocument?.detail !== detail) dataToSave.detail =  detail
    if (firestoreDocument?.coach !== coach) dataToSave.coach =  coach

    if (!isObjectEmpty(dataToSave)) {
      dataToSave.app = firebaseApp;
      if (firestoreDocument?.id) dataToSave.documentId = firestoreDocument.id;
      UpdateTimetableEvent(dataToSave)
      .then(() => {
        // Firestore collection update successful
        console.log('openMediaUploadAlert', openMediaUploadAlert);
        setOpenMediaUploadAlert({ success: true, content: successAlertMessage });
      })
      .catch((error) => {
        setOpenMediaUploadAlert({ success: false, content: failureAlertMessage + ' ' + error});
      });
    }
  }

  const createdSortedArrayFromMapKeys = (sortByKey, mapToSort) => {
    // Remove 'placeholder' entry if it exists
    const { placeholder, ...mapWithoutPlaceholder } = mapToSort;
    // Convert object values to an array of [key, value] pairs
    const mediaArray = Object.entries(mapWithoutPlaceholder);
    // Sort the array based on the 'index' field in the value object
    mediaArray.sort((a, b) => a[1][sortByKey] - b[1][sortByKey]);
    // Extract the keys from the sorted array
    const mediaKeys = mediaArray.map((item) => item[0]);
    return mediaKeys; // Return the sorted keys array
  };

  // Needed for media upload feedback alert
  const [openMediaUploadAlert, setOpenMediaUploadAlert] = useState(null);

  // Show media edit dialog entry
  const [openCalendarMediaDialog, setOpenCalendarMediaDialog] = useState(false); // used for editing a calendar entry

  // Show overwrite dialog
  const [openOverwriteMediaForm, setOpenOverwriteMediaForm] = useState(false);

  // Add object to map. If placeholder item exists, delete it and add it after the object being added
  const addToMediaMap = (objectToAdd) => {
    const placeholderKey = 'placeholder';
    const key = objectToAdd.cloudStoragePath;

    setVisibleMediaMap((prevMediaMap) => {
      // Check if the objectToAdd has an index property, if not, set it as the size of the visibleMediaMap
      const index = objectToAdd.index !== undefined ? objectToAdd.index : Object.keys(prevMediaMap).length;

      if (placeholderKey in prevMediaMap) delete prevMediaMap[placeholderKey];
      return { ...prevMediaMap, [key]: { ...objectToAdd, index: index}, [placeholderKey]: { placeholder: true }};
    });
  };
  // Function to remove an entry from visibleMediaMap
  const removeFromVisibleMediaMap = (keyToRemove) => {
    setVisibleMediaMap((prevVisibleMediaMap) => {
      const updatedMap = { ...prevVisibleMediaMap };
      delete updatedMap[keyToRemove]; // Remove the entry with the specified key from the object
      return updatedMap;
    });
  };
    
  const handleCalendarMediaEditDialogFormResponse = ({ save, fileObject, fileURL }) => {

    if (save && fileObject) {
      
      const fileName = fileObject.name;
      const filePath = 'media/' + fileName;
      const fileType = fileObject.type;
      addToMediaMap({ 
        cloudStoragePath: filePath, 
        placeholder: false, 
        cloudSaved: false, 
        url: fileURL,
        metadata: {contentType: fileType},
        fileObject: fileObject,
       });

    } else {
      // User closed the dialog
    }
  };

  const handleOverwriteMediaFormResponse = (overwrite) => {
    if (overwrite) {
      /*AddFile({ 
        auth: null, 
        storagePath:'media', 
        file: media,
        autoOverwrite: true,
        progressCallback: (progress) => {
          setOpenMediaUploadAlert({success: null, content: 'Uploading meida: ' + progress + '%'})
        },
      })
      .then((storagePath) => {
        setOpenMediaUploadAlert({success: true, content: 'Media uploaded successfully'})
        addItemToMediaList({ref: {storagePath}, placeholder: false});
      })
      .catch((error) => {
        setOpenMediaUploadAlert({success: false, content: 'Media uploaded failed'})
      }); */
    }
    
  }

  const handleMediaOnClick = (media) => {
    setChosenMedia(media);
    setOpenCalendarMediaDialog(true);
  }

  // Used to track the size of the window
  const [isXs, setIsXs] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsXs(window.innerWidth <= 600); // Set isXs to true if viewport width is less than or equal to 600px
      //console.log('handleResize', 'window.innerWidth', window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <DialogTitle id="alert-dialog-title" style={{ padding: 0 }}>
                <TextField
                  id="titleLabel"
                  label="Title"
                  type="text"
                  variant="standard"
                  value={title}
                  required
                  size="small"
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </DialogTitle>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DateTimeField
                      label="Start Date Time"
                      value={startDateTime}
                      required
                      size="small"
                      variant="standard"
                      format="dd/MM/yy HH:mm"
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => setStartDateTime(date)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimeField
                      label="End Date Time"
                      value={endDateTime}
                      required
                      size="small"
                      variant="standard"
                      format="dd/MM/yy HH:mm"
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      onChange={(date) => setEndDateTime(date)}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
               label="Detail"
               type="text"
               variant="standard"
               value={detail}
               size="small"
               fullWidth
               multiline
               InputProps={{ disableUnderline: true }}
               onChange={(e) => setDetail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container>
                {Object.values(visibleMediaMap)
                  .sort((a, b) => a.index - b.index) // Sort by the "index" property
                  .map((mediaItem, index) => (
                  <Grid 
                  item xs={3.9} 
                  sm={5.8}
                    style={{
                      position: "relative",
                      paddingTop: isXs ? '33%' : '50%', // Use 25% if isXs is true, otherwise use 50%
                      backgroundColor: mediaItem.placeholder ? '#ddd' : undefined,
                      borderRadius: "2px", // Adjust roundness
                      border: mediaItem.placeholder ? '1px dashed #888' : '1px solid #888', // Adjust the border properties as needed
                      cursor:  'pointer',
                      marginRight: '2.5px',
                      marginBottom: '2.5px',
                    }}
                    onClick={() => handleMediaOnClick(mediaItem)}
                    key={index}
                  >
                    {mediaItem.placeholder ? (
                      <UploadFileIcon
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          userSelect: "none",
                        }}
                      />
                    ) : (
                      <img
                        src={mediaItem.url}
                        alt="Media Thumbnail"
                        onContextMenu={(event) => handleMediaRightClick(event, mediaItem, index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                  id="Coach"
                  label="Coach"
                  type="text"
                  variant="standard"
                  value={coach}
                  required
                  size="small"
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => setCoach(e.target.value)}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
        {!isProcessing && openMediaUploadAlert && <MediaUploadFeedback setOpen={setOpenMediaUploadAlert} open={openMediaUploadAlert} />}
        {positionRightClick && <DynamicPositionMenu  position={positionRightClick} setPosition={setPositionRightClick} menu1={rightClickMenu_MoveUp} handleMenu1={handleDynamicPositionRightClickMenu_MoveUp} menu2={rightClickMenu_MoveDown} handleMenu2={handleDynamicPositionRightClickMenu_MoveDown} menu3={"Remove From Event"} handleMenu3={handleDynamicPositionRightClickMenu_DeleteFromEvent}></DynamicPositionMenu>}
      </Dialog>
      {openCalendarMediaDialog && <MediaEditForm setOpen={setOpenCalendarMediaDialog} saveDirectChanges={false} handleResponse={handleCalendarMediaEditDialogFormResponse} chosenMedia={chosenMedia}/>}
      {openOverwriteMediaForm && <OverwriteMedia     setOpen={setOpenOverwriteMediaForm} handleResponse={handleOverwriteMediaFormResponse}/>}            
      {openMediaRemovalForm && <MediaRemovalAlert setOpen={setOpenMediaRemovalForm} handleResponse={handleMediaRemovalAlert} dialogTitleText={"Remove Media From Event"} dialogCheckboxLabel={"Do you want to also delete this media from cloud storage? Once the event has been saved, this action cannot be undone."} dialogAgreeText={"Remove"} dialogDisagreeText={"Cancel"} />}  
    </div>
  );
}

/* Return true if the two dates are the same, false if they are different. 
Date comparison is achieved by using the .getTime() function.
*/
function areDatesSame(date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return false;
  }

  return date1.getTime() === date2.getTime();
}

// Return true if the object is empty, false if it's not
function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
