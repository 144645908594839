import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


export default function OverwriteMedia({setOpen, handleResponse}) {
  
  const handleClose = () => {
    handleResponse(false);
    setOpen(false);
  };

  const handleSave = () => {
    handleResponse(true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {'Overwrite media?'}
        </DialogTitle>
        <DialogContent>
          {'The file already exists, do you want to overwrite it?'}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{'No'}</Button>
          <Button onClick={handleSave}>{'Overwrite'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}