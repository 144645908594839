import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function CalendarDialogAlert({setOpen, handleResponse, dialogTitleText, dialogContentText, dialogAgreeText, dialogDisagreeText}) {
  const handleClose = () => {
    handleResponse(false); // Notify parent component with user's response
    setOpen(false); // closes dialogue box
  };
  
  const handleAgree = () => {
    handleResponse(true); // Notify parent component with user's response
    setOpen(false); // closes dialogue box
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{dialogDisagreeText}</Button>
          <Button onClick={handleAgree} autoFocus>{dialogAgreeText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}