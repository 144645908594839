// authenticatedContent.js

import React from 'react';

import InitAppBar from '../../js/mui/appBar.js'
import { InitTimetable } from './timetable.js'

export function AuthenticatedContent({role, app, auth}) {

  return (
    <div>
      <InitAppBar role={role} />
      <InitTimetable app={app} />
      {/* Additional content for authenticated users */}
    </div>
  );

}
