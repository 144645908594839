import { useRef, useState , useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  addHours,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  getDay,
  startOfDay
} from "date-fns";

// Navigation arrows for days
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { getFirestore, collection, onSnapshot, query, orderBy, where} from "firebase/firestore";

import CalendarDialogEditForm from "../mui/Dialogs/Input Forms/CalendarEventEdit.js";
import CalendarDialogAlert from "../mui/Dialogs/Confirmations/CalendarEventDelete.js";
import { DynamicPositionMenu } from "../mui/Menu.js"

import { DeleteTimetableEvent } from '../firestore/change-timetable.js'

const Calendar = ({ app }) => {
  
  const [currentDateInSelectedWeek, setCurrentDateInSelectedWeek] = useState(new Date());
  const [weekNumber, setWeekNumber] = useState(getWeek(currentDateInSelectedWeek), { weekStartsOn: 1 });
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [gridRightClickPosition, setGridRightClickPosition] = useState(null); // set position of grid right click menu
  const [eventRightClickPosition, setEventRightClickPosition] = useState(null); // set position of event right click menu

  // Store documents from firestore listener
  const [firestoreDocuments, setFirestoreDocuments] = useState(new Map());
  // Array holding the divs created from the firestore documents
  const [timetableEventDivs, setTimetableEventDivs] = useState([]);

  // Show dialogue box
  const [openCalendarDialogAlert, setOpenCalendarDialogAlert] = useState(false); // used for confirming if the user wants to delete a calendar entry
  //const [openCalendarDialogForm, setOpenCalendarDialogForm] = useState(false); // used for editing a calendar entry
  const [calendarEvent, setCalendarEvent] = useState(null);
  const [openCalendarEvent, setOpenCalendarEvent] = useState(false);

  const dayContentRef = useRef(null); 
  const [dayContentTopPosition, setDayContentTopPosition] = useState(null);
  const [dayContentLeftPosition, setDayContentLeftPosition] = useState(null);
  const [dayContentWidth, setDayContentWidth] = useState(null);

  // Whenever dayContentRef changes or window resizes, recalculate its positions so they can be used in convert positions to date/times
  useEffect(() => {
    const handleResize = () => {
      if (dayContentRef) {
        const dayContentRect = dayContentRef.current.getBoundingClientRect();
  
        // Calculate the top position
        const topPosition = dayContentRect.top + window.scrollY;
        setDayContentTopPosition(topPosition);
  
        // Calculate the width
        setDayContentWidth(dayContentRect.width);
  
        // Calculate the left position
        const leftPosition = dayContentRect.left + window.scrollX;
        setDayContentLeftPosition(leftPosition);
      }
    };
  
    // Attach the resize handler to the window's onresize property
    window.onresize = handleResize;
  
    // Initial calculation when the component mounts
    handleResize();
  
    // Clean up the event handler when the component unmounts
    return () => {
      window.onresize = null;
    };
  }, [dayContentRef]);
  


  //const [interactiveFirestoreDocumentId, setInteractiveFirestoreDocumentId] = useState(null);
  
  const rowHeight = "8vh";
  const activeHours = [6, 7, 8, 9, 17, 18, 19, 20, 21]; // Define the active hours here

  // subscribe to firestore documents first
  useEffect(() => {
    const unsubscribe = subscribeToTimetableChanges(app, currentDateInSelectedWeek, setFirestoreDocuments);

    return () => {
      unsubscribe();
      //console.log("Firestore listener unsubscribed.");
    };
  }, [app, currentDateInSelectedWeek]);

  // then, create div properties from the firestore documents
  useEffect(() => {
    createTimetableEventDivs(weekNumber, activeHours, rowHeight, firestoreDocuments, setTimetableEventDivs);
  }, [firestoreDocuments]);

  

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentDateInSelectedWeek(subMonths(currentDateInSelectedWeek, 1));
    }
    if (btnType === "next") {
      setCurrentDateInSelectedWeek(addMonths(currentDateInSelectedWeek, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentDateInSelectedWeek(subWeeks(currentDateInSelectedWeek, 1));
      setWeekNumber(getWeek(subWeeks(currentDateInSelectedWeek, 1)));
    }
    if (btnType === "next") {
      setCurrentDateInSelectedWeek(addWeeks(currentDateInSelectedWeek, 1));
      setWeekNumber(getWeek(addWeeks(currentDateInSelectedWeek, 1)));
    }
  };

  // On selecting a day
  /*
  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(dayStr);
  };
  */

  const handleEventClick = (firestoreDocId) => {
    //console.log('left click');
    //setInteractiveFirestoreDocumentId(firestoreDocId);
    //setOpenCalendarDialogForm(true); // open dialog form box for edit
    setCalendarEvent({firestoreDocId: firestoreDocId});
    setOpenCalendarEvent(true);
  };
    
  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="title row flex-middle">
        {/*<div className="col col-start">
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </div> 
        </div>*/}
        <div className="col col-center">
          <span>{format(currentDateInSelectedWeek, dateFormat)}</span>
        </div>
        {/*<div className="col col-end">
          <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div>
        </div>*/}
      </div>
    );
  };

  const handleEventRightClick = (event, firestoreDocId) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent the event from propagating to the parent
    setEventRightClickPosition({
      x: event.clientX,
      y: event.clientY,
    });
    setCalendarEvent({firestoreDocId: firestoreDocId});
  };

  const handleEventRightClickDelete = () => {
    setEventRightClickPosition(null); // close menu
    setOpenCalendarDialogAlert(true); // open dialog alert box for confirmation
  };

  const handleGridRightClick = (event) => {
    event.preventDefault();
    setGridRightClickPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleGridRightClickAdd = () => {
    // Calculate the time block that was clicked
    // Get the Vh that was clicked
    const clickedVh = pixelsToVh(gridRightClickPosition.y);
    // Get the Vh of the top of the calendar
    const calendarTopVh = pixelsToVh(dayContentTopPosition);
    const relativeClickedVh = clickedVh - calendarTopVh;
    // Get the relative Vh that was clicked
    const hourClicked = relativeClickedVh / extractFirstNumericPart(rowHeight);
    // Round it to the nearest hour
    const roundedHourClicked = Math.round(hourClicked);
    // Return the hour from available hours
    let startTime = activeHours[roundedHourClicked]
    if (!startTime) startTime = activeHours[roundedHourClicked - 1]
  
    // Calculate the date block that was clicked
    // Get the x position, no need to convert from pixels
    const clickedX = gridRightClickPosition.x;
    // Get the relative position by accounting for the let position of the calendar
    const relClickedX = dayContentLeftPosition - clickedX;
    // Get the number that we'll be rounding to, this will give us the closest day
    const dayColumnWidth = dayContentWidth / 7;
    // Determine the clicked day number (0 for the first column, 1 for the second, and so on)
    const clickedDayNumber = (Math.floor(relClickedX / dayColumnWidth) * -1)
    // get the selected week start date
    const selectedWeekStartDate = startOfWeek(currentDateInSelectedWeek, { weekStartsOn: 0 });
    // add number of days to this
    const clickedStartDate = addDays(selectedWeekStartDate, clickedDayNumber)
    const clickedStartDateTime = addHours(clickedStartDate, startTime)
    // calculate end date time (just add 1 hour to start)
    const clickedEndDateTime = addHours(clickedStartDateTime, 1)
    
    setCalendarEvent({startDateTime: clickedStartDateTime, endDateTime: clickedEndDateTime});
    setGridRightClickPosition(null); // close menu
    setOpenCalendarEvent(true);
  };

  const handleEventRightClickAdd = () => {
    // Calculate the time block that was clicked
    // Get the Vh that was clicked
    const clickedVh = pixelsToVh(eventRightClickPosition.y);
    // Get the Vh of the top of the calendar
    const calendarTopVh = pixelsToVh(dayContentTopPosition);
    const relativeClickedVh = clickedVh - calendarTopVh;
    // Get the relative Vh that was clicked
    const hourClicked = relativeClickedVh / extractFirstNumericPart(rowHeight);
    // Round it to the nearest hour
    const roundedHourClicked = Math.round(hourClicked);
    // Return the hour from available hours
    let startTime = activeHours[roundedHourClicked]
    if (!startTime) startTime = activeHours[roundedHourClicked - 1]
  
    // Calculate the date block that was clicked
    // Get the x position, no need to convert from pixels
    const clickedX = eventRightClickPosition.x;
    // Get the relative position by accounting for the let position of the calendar
    const relClickedX = dayContentLeftPosition - clickedX;
    // Get the number that we'll be rounding to, this will give us the closest day
    const dayColumnWidth = dayContentWidth / 7;
    // Determine the clicked day number (0 for the first column, 1 for the second, and so on)
    const clickedDayNumber = (Math.floor(relClickedX / dayColumnWidth) * -1)
    // get the selected week start date
    const selectedWeekStartDate = startOfWeek(currentDateInSelectedWeek, { weekStartsOn: 0 });
    // add number of days to this
    const clickedStartDate = addDays(selectedWeekStartDate, clickedDayNumber)
    const clickedStartDateTime = addHours(clickedStartDate, startTime)
    // calculate end date time (just add 1 hour to start)
    const clickedEndDateTime = addHours(clickedStartDateTime, 1)
    
    setCalendarEvent({startDateTime: clickedStartDateTime, endDateTime: clickedEndDateTime});
    setEventRightClickPosition(null); // close menu
    setOpenCalendarEvent(true);
  };

  

  const renderDays = () => {
    const startDate = startOfWeek(currentDateInSelectedWeek, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentDateInSelectedWeek, { weekStartsOn: 1 });

    let divs = [];
    let days = [];
    let dayNames = [];
    let dayNumbers = [];
    let dayContent = [];

    let prevWeekColumn = [];
    let nextWeekColumn = [];

    let day = startDate;

    // set up day names and day numbers

    dayContent.push(
      <div style={{
        position: "absolute", 
        height: "72vh",
        width: `calc(100% - (55px + 40px)`,
        
      }}>
        {renderHours()}
      </div>
    );
    
    while (day <= endDate) {
      for (let i = 1; i < 8; i++) {
        
        // day names
        dayNames.push(
          <div
            className={`col col-center ${isSameDay(day, new Date()) ? "today" : ""}`}
              style={{
                textTransform: "uppercase",
                fontWeight: 400,
                color: "var(--text-color-light)",
                fontSize: "70%",
              }}
          >
            {format(day, "EEE")}
          </div>
        );

        // day numbers
        dayNumbers.push(
          <div
            className={`col col-center ${isSameDay(day, new Date()) ? "today" : ""}`}
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
              color: "var(--text-color-light)",
              fontSize: "70%",
              border: "1px solid #000", // Add the desired border style here
            }}
          >
            {format(day, "d")}
          </div>
        );

        const dayContentDetail = [];

        // get all content for the day
        const dayNumberContent = timetableEventDivs.filter(entry => entry.weekDayNumber === i);
        let accumulativeHeight = "0vh"; // needed because we're using relative displays that consume height
        // create divs for all the day's content
        let iterNumber = 0;
        dayNumberContent.forEach(content => {

          let firestoreDocId;
          const clashList = content.clashList;
          let topPosition = content.topPosition;
          const height = content.height;

          topPosition = `${parseFloat(topPosition) - parseFloat(accumulativeHeight)}vh`;
          accumulativeHeight = `${parseFloat(accumulativeHeight) + parseFloat(height)}vh`;;

          iterNumber = iterNumber + 1;

          if (clashList) {
            const clashes = [];
            clashList.forEach(clash => {
              firestoreDocId = clash.document.id;
              clashes.push(
                <div
                  className={`col col-center ${isSameDay(day, new Date()) ? "today" : ""}`}
                  style={{
                    position: "relative",
                    height: clash.height,
                    top: clash.topPosition,
                    width: clash.width,
                    border: "1px solid #000", // Add the desired border style here
                    boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
                    backgroundColor: "blue",
                  }}
                  onClick={() => handleEventClick(firestoreDocId)}
                  onContextMenu={(event) => handleEventRightClick(event, firestoreDocId)}
                >
                </div>
              );
            });

            dayContentDetail.push(
              <div 
                style={{
                  display: "flex",
                  position: "relative",
                  height: height,
                  top: topPosition,
                  boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
                }}
              >
                {clashes}
              </div>
            ); // Push the clashes array as a single element
          } else {
            firestoreDocId = content.document.id;
            dayContentDetail.push(
              <div
                className={`col col-center ${isSameDay(day, new Date()) ? "today" : ""}`}
                style={{
                  position: "relative",
                  height: content.height,
                  top: topPosition,
                  width: content.width,
                  border: "0px solid #000", // Add the desired border style here
                  boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
                  backgroundColor: "blue",
                }}
                onClick={() => handleEventClick(firestoreDocId)}
                onContextMenu={(event) => handleEventRightClick(event, firestoreDocId)}
              >
              </div>
            );
          }
        });

        dayContent.push(
          <div
            className={`col col-center ${isSameDay(day, new Date()) ? "today" : ""}`}
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
              color: "var(--text-color-light)",
              fontSize: "70%",
              border: "1px solid #000", // Add the desired border style here
              height: `calc(${rowHeight} * ${activeHours.length})`,
              overflow: "hidden", // stop child elements from overflowing this div
              boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
            }}
          >
            {dayContentDetail}
          </div>
        );
        
        day = addDays(day, 1);
      }
    }

    //eventArray
    

    days.push(<div className={'dayNames row'}>{dayNames}</div>);
    days.push(<div className={'dayNumbers row'}>{dayNumbers}</div>);


    days.push(
      <div
        ref={dayContentRef}
        className={'dayContent row'}
        style={{
          position: "block",
          top: "100%",
          left: 0,
          right: 0,
          width: "100%",
          height: `calc(${rowHeight} * ${activeHours.length})`,
          overflow: "hidden", // stop child elements from overflowing this div
          boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
        }}
        onContextMenu={(event) => handleGridRightClick(event)} // handle right click
      >
        {dayContent}
      </div>
    );

    prevWeekColumn.push(
      <div className="prevWeek" onClick={() => changeWeekHandle("prev")}>
        <NavigateBeforeIcon />
      </div>
    )
    prevWeekColumn.push(
      
    )
    divs.push(
      <div className="prevWeekColumn">
        {prevWeekColumn}
      </div>
    )
    divs.push(<div className={'days row'}>{days}</div>);
    nextWeekColumn.push(
      <div className="nextWeek" onClick={() => changeWeekHandle("next")}>
        <NavigateNextIcon />
      </div>
    )
    divs.push(
      <div className="nextWeekColumn">
        {nextWeekColumn}
      </div>
    )

    return (
      <div className="days" style={{display: "flex" }}>
        {divs}
      </div>
    );
    
  };
  

  const renderHours = () => {
    const rows = [];
    
    let accumilitiveHeight = "0vh";
    let hourIndex = 0;

    for (let hour of activeHours) {
      let displayHour = hour;
      let ampm = " am";
      
      if (hour > 12) {
        displayHour = hour - 12;
        ampm = " pm";
      }

      rows.push(
        <div className={`hour-${hour}`} 
          style={{
            position: "absolute",
            height: `${rowHeight}vh`,
            top: `${accumilitiveHeight}vh`,
            borderBottom: "1px solid black",
            borderTop: hourIndex === 0 ? "1px solid black" : "none",
            width: hourIndex === 0 ? "5px " : `calc(100% + 5px)`,
            marginLeft: "-5px",
          }}
        >
          <span className={`hour-${hour}`} 
            style={{
              position: "absolute",
              marginLeft: "-45px",
            }}
          >
            {`${displayHour}${ampm}`}
          </span>
        </div>
      );

      accumilitiveHeight = parseFloat(accumilitiveHeight) + parseFloat(rowHeight);
      hourIndex++; // increase by 1
    }
  
    return (
      <div className="hours" 
        style={{
          width: "100%", 
          boxSizing: "border-box", // prevent minor mis alignments due to borders increasing the size
         }}
      >
        {rows}
      </div>
    );
    
    
    
  };

  const handleDeleteAlertDialogResponse = (isAgreed) => {
    if (isAgreed) {
      // User clicked "Agree"
      DeleteTimetableEvent({app: app, documentId: calendarEvent.firestoreDocId})
    } else {
      // User clicked "Disagree" or closed the dialog
      console.log('Delete disagreed');
    }
  };

  const handleSaveDialogFormResponse = (save) => {
    if (save) {
      // User clicked "save"
      console.log('save agreed');
    } else {
      // User closed the dialog
      console.log('save disagreed');
    }
  };

  const getDeleteDetails = () => {
    const document = firestoreDocuments.get(calendarEvent.firestoreDocId);

    if (document) {
      const { title, startDateTime, endDateTime } = document;

      if (startDateTime && endDateTime) {
        const formattedStartDateTime = format(startDateTime.toDate(), 'dd-MM HH:mm');
        const formattedEndDateTime = format(endDateTime.toDate(), 'dd-MM HH:mm');
        if (title) {
          return `Are you sure you want to delete '${title}' scheduled from ${formattedStartDateTime} to ${formattedEndDateTime}?`;
        } else {
          return `Are you sure you want to delete this event scheduled from ${formattedStartDateTime} to ${formattedEndDateTime}?`;
        }
      }
    }

    return 'Are you sure you want to delete this event?';
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {openCalendarDialogAlert && <CalendarDialogAlert       setOpen={setOpenCalendarDialogAlert} handleResponse={handleDeleteAlertDialogResponse} dialogTitleText={"Confirmation Required"} dialogContentText={getDeleteDetails()} dialogAgreeText={"Delete"} dialogDisagreeText={"Cancel"} />}
      {openCalendarEvent && <CalendarDialogEditForm     setOpen={setOpenCalendarEvent} handleResponse={handleSaveDialogFormResponse} firestoreDocument={firestoreDocuments.get(calendarEvent.firestoreDocId)} newFirestoreDocument={calendarEvent} firebaseApp={app} />}
      {gridRightClickPosition && <DynamicPositionMenu  position={gridRightClickPosition} setPosition={setGridRightClickPosition} menu1={"Add"} handleMenu1={handleGridRightClickAdd} ></DynamicPositionMenu>}
      {eventRightClickPosition && <DynamicPositionMenu  position={eventRightClickPosition} setPosition={setEventRightClickPosition} menu1={"Add"} handleMenu1={handleEventRightClickAdd} menu2={"Delete"} handleMenu2={handleEventRightClickDelete} ></DynamicPositionMenu>}
    
    </div>
  );
};

export default Calendar;




function subscribeToTimetableChanges(app, currentDateInSelectedWeek, setFirestoreDocuments) {
  const firestore = getFirestore(app);
  const timetableCollection = collection(firestore, 'timetable');

  const startDate = startOfWeek(currentDateInSelectedWeek, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(currentDateInSelectedWeek, { weekStartsOn: 1 });

  /*
  
  const startDateTimeQuery = query(
    timetableCollection,
    where('startDateTime', '<=', endDate), // Event starts on or before the end of the week
    orderBy('startDateTime')
  );

  const endDateTimeQuery = query(
    timetableCollection,
    where('endDateTime', '>=', startDate), // Event ends on or after the start of the week
    orderBy('endDateTime')
  );

  const unsubscribe = onSnapshot(startDateTimeQuery, (startSnapshot) => {
    onSnapshot(endDateTimeQuery, (endSnapshot) => {
      const startDocs = startSnapshot.docs.reduce((map, doc) => {
        map.set(doc.id, { id: doc.id, ...doc.data() });
        return map;
      }, new Map());

      console.log('startDocs', startDocs);
  
      const endDocs = endSnapshot.docs.reduce((map, doc) => {
        map.set(doc.id, { id: doc.id, ...doc.data() });
        return map;
      }, new Map());

      console.log('endDocs', endDocs);
  
      // Merge the results by finding events that satisfy both conditions
      const mergedDocs = Array.from(startDocs.values()).filter((startDoc) => {
        return Array.from(endDocs.values()).some((endDoc) => {
          return (
            startDoc.startDateTime <= endDoc.endDateTime &&
            startDoc.endDateTime >= endDoc.startDateTime
          );
        });
      });

      console.log('mergedDocs', mergedDocs);
  
      const firestoreDocumentsMap = mergedDocs.reduce((map, doc) => {
        map.set(doc.id, doc);
        return map;
      }, new Map());

  
      console.log('firestore listener subscribed', firestoreDocumentsMap);
      setFirestoreDocuments(firestoreDocumentsMap);
    });

  });

  */

  const newQuery = query(
    timetableCollection,
    where('endDateTime', '>=', startDate)
  );

  const unsubscribe = onSnapshot(newQuery, (snapshot) => {
    const documents = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    
    // Filter documents based on 'startDateTime' and 'endDateTime'
    const filteredDocuments = documents.filter((doc) => {
      const startDateTime = doc.startDateTime.toDate();
      const endDateTime = doc.endDateTime.toDate();
      return startDateTime <= endDate && endDateTime >= startDate;
    });

    const firestoreDocumentsMap = filteredDocuments.reduce((map, doc) => {
      map.set(doc.id, doc);
      return map;
    }, new Map());

    //console.log('firestore listener subscribed', firestoreDocumentsMap);
    setFirestoreDocuments(firestoreDocumentsMap);

  });

  return unsubscribe;
}


function createTimetableEventDivs(weekNumber, activeHours, rowHeight, firestoreDocuments, setTimetableEventDivs) {

  const floatingRowHeight = parseFloat(rowHeight);
  const firstHour = activeHours[0];
  const lastHour = activeHours[activeHours.length - 1];

  //const eventArray = Object.values(firestoreDocuments).flatMap((document) =>  {
  //const eventArray = firestoreDocuments.flatMap((document) => {
  //const eventArray = Array.from(firestoreDocuments.values()).flatMap((document) => {
  //const eventArray = firestoreDocuments.map(([key, value]) => {
  //firestoreDocuments.forEach((document, key) => {
  const flattenedArray = Array.from(firestoreDocuments.values());
  const eventArray = flattenedArray.flatMap((document) => {

    const startDateTime = document.startDateTime.toDate();
    const startDateMidnight = startOfDay(startDateTime);
    let divStartDateTime = startDateTime;
    const endDateTime = document.endDateTime.toDate();
    let divEndDateTime = endDateTime;

    // e.g. monday will be 1, sunday will be 7
    let weekDayNumber = parseInt(getDay(startDateTime));
    
    // Calculate the number of days the event spans
    const startDay = parseInt(format(startDateTime, 'd'));
    const endDay = parseInt(format(endDateTime, 'd'));
    const daySpan = endDay - startDay;

    if (daySpan >= 1) {
      // multiple divs need to be created because the day spans 1 or more days
      const eventArray = [];

      // create div for starting day and any extra days as required
      for (let dayNumber = 0; dayNumber <= daySpan; dayNumber++) {
        let topPosition = 0;
        let height = 0;

        if (dayNumber < 1) {
          // multiple days, this is the first day so the height will be max
          topPosition = calculateTopPositionSingleDayDiv(startDateTime, floatingRowHeight, activeHours);
          height = calculateMaxHeightDayDiv(topPosition, floatingRowHeight, activeHours.length);
          divEndDateTime = addHours(startDateMidnight, lastHour);
        } else {
            // multiple days, this is not the first day
            weekDayNumber = weekDayNumber + 1;
            if (weekDayNumber > 7) weekDayNumber = 1;
            
           // The div will always start at the top if the event started on a previous day
           topPosition = 0;
           divStartDateTime = addDays(addHours(startDateMidnight, firstHour), dayNumber);
           divEndDateTime = addDays(addHours(startDateMidnight, lastHour), dayNumber);

           // Calculate height
           if ((dayNumber + 1) <= daySpan) {
             // the height will always be 100% if the event finishes on the day after of which we are looping
             height = "100%"
           } else {
             // if the day finishes on the day we are looping, then we need to calculate the correct height as it won't be 100%
             height = calculateHeightSingleDayDiv(endDateTime, topPosition, floatingRowHeight, activeHours);
           }
        }

        if (weekNumber === getWeek(divStartDateTime, { weekStartsOn: 1 })) {
          eventArray.push({
              divStartDateTime: divStartDateTime,
              divEndDateTime: divEndDateTime,
              topPosition: topPosition,
              height: height,
              weekDayNumber: weekDayNumber,
              document: document,
          });
        }
      }
    
      return eventArray;

    } else {
      // single div needs to be created

      // Calculate top position
      const topPosition = calculateTopPositionSingleDayDiv(startDateTime, floatingRowHeight, activeHours);

      // Calculate height
      const height = calculateHeightSingleDayDiv(endDateTime, topPosition, floatingRowHeight, activeHours);

      return ({
        divStartDateTime: divStartDateTime,
        divEndDateTime: divEndDateTime,
        topPosition: topPosition,
        height: height,
        weekDayNumber: weekDayNumber,
        document: document,
      });
    }
  });

  const updatedEvents = [...eventArray]; // Create a copy of the array
  // Mapping object to keep track of clashes for each event

  for (let i = 0; i < updatedEvents.length; i++) {
  const currentEvent = updatedEvents[i];
  const clashList = [];
  let removeI = false;

  for (let j = 0; j < updatedEvents.length; j++) {
    if (i !== j) {
      const otherEvent = updatedEvents[j];

      // Check for clashes
      //console.log('Checking for clash against', currentEvent, otherEvent);
      if (otherEvent.divStartDateTime >= currentEvent.divStartDateTime && otherEvent.divEndDateTime <= currentEvent.divEndDateTime) {

        console.log('Found clash', currentEvent, otherEvent);
        //clashList.push(currentEvent); // Add index of clashing event to clashMap

        removeI = true;
        // remove entry
        //updatedEvents.splice(i, 1);
        //i--; // Decrement index after splice to account for the removed entry

        // remove entry
        updatedEvents.splice(j, 1);
        j--; // Decrement index after splice to account for the removed entry

        clashList.push(otherEvent); // Add index of clashing event to clashMap
      }
    }
  }
  if (removeI) { 
    clashList.push(currentEvent); // Add index of clashing event to clashMap
    updatedEvents.splice(i, 1);
    i--; // Decrement index after splice to account for the removed entry
    removeI = false;
  }


  // add clashed entries as children of a new parent in the array
  if (clashList.length > 1) {
    const smallestTopPosition = `${Math.min(...clashList.map(entry => parseFloat(entry.topPosition)))}vh`;
    let largestHeight = 0;
    
    // loop through to find the largest value whilst maintaining units. Assume 100% is greater than any vh unit
    for (const entry of clashList) {
      if (entry.height === "100%") {
        largestHeight = entry.height;
        break; // Exit the loop, "100%" is always considered the greatest
      } else {
        const parsedHeight = parseFloat(entry.height);
        if (parsedHeight > parseFloat(largestHeight)) {
          largestHeight = entry.height;
        }
      }
    }

    const clashMap = {
      weekDayNumber: clashList[0].weekDayNumber,
      topPosition: smallestTopPosition,
      height: largestHeight,
      clashList: clashList.map(entry => {
        // Perform calculations on specific fields within each entry
        const updatedTopPosition = `${parseFloat(entry.topPosition) - parseFloat(smallestTopPosition)}vh`;
        // Return the modified entry with the calculated field
        return {
          ...entry,
          topPosition: updatedTopPosition,
        };
      })
    }
    updatedEvents.splice(i + 1, 0, clashMap);
    i++; // Increment index after splice to skip the newly added entry
  }
  }
  setTimetableEventDivs(updatedEvents);
}

function calculateTopPositionSingleDayDiv(startDateTime, floatingRowHeight, activeHours) {
  const startHour = parseFloat(format(startDateTime, 'H'));
  const startMinute = parseFloat(format(startDateTime, 'm'));
  // Get the position of the hour in the list
  const startHourPosition = activeHours.indexOf(startHour);

  // We know what the height of each hour is, so this multiplied by the position of the hour gives us the start position (only of the hour)
  let startPosition = startHourPosition * floatingRowHeight;

  // Now we need to calculate how much extra the minutes will add
  startPosition = startPosition + ((startMinute / 60) * floatingRowHeight);
  return `${startPosition}vh`;
}

function calculateHeightSingleDayDiv(endDateTime, topPosition, floatingRowHeight, activeHours) {
  const endHour = parseFloat(format(endDateTime, 'H'));
  const endMinute = parseFloat(format(endDateTime, 'm'));
  // Get the position of the hour in the list
  const endHourPosition = activeHours.indexOf(endHour);
  // We know what the height of each hour is, so this multiplied by the position of the hour gives us the end position (only of the hour)
  let endPosition = endHourPosition * floatingRowHeight;
  // Now we need to calculate how much extra the minutes will add
  endPosition = endPosition + ((endMinute / 60) * floatingRowHeight);
  return `${endPosition - parseFloat(topPosition)}vh`;
}

function calculateMaxHeightDayDiv(topPosition, floatingRowHeight, totalHourEntries) {
  // We know what the height of each hour is, so this multiplied by the position of the hour gives us the end position (only of the hour)
  let endPosition = totalHourEntries * floatingRowHeight;
  return `${endPosition - parseFloat(topPosition)}vh`;
}

function pixelsToVh(pixels) {
  const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  return (pixels * 100) / viewportHeight;
}

function extractFirstNumericPart(inputString) {
  const match = inputString.match(/\d+/);

  if (match) {
    const numericPart = match[0];
    return numericPart;
  }

  return null; // Return null if no numeric part is found
}